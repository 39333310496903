#topMenu {
    background-color: #005158 !important;
}

#topMenu .nav-link {
    color: white !important;
    cursor: pointer;
}

#topMenu .thumb {
    display: inline-block;
}

#topMenu .name {
    margin-right: 55px;
}

#topMenu .user {
    width: 32px;
    height: 32px;
    transform: translateX(-40px);
    top: 5px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: absolute;
}